import React from "react";
import Thumbnail from "app/components/Thumbnail";
import Div from "app/components/Div";
import env from "helpers/env";
import { Class } from "services/graphql";

interface Props {
  classData: Class;
  onClick?(): void;
}

export const ClassThumbnail = ({ classData, onClick }: Props) => {
  return (
    <Div
      width="100%"
      height="100%"
      position="relative"
      cursor={onClick ? "pointer" : "default"}
      onClick={() => onClick && onClick()}
    >
      <Thumbnail
        src={`${env("PUBLIC_ASSET_PATH")}/class-thumbnails/${
          classData.slug
        }-basic.jpg?w=1200`}
        placeholderSrc={`${env("PUBLIC_ASSET_PATH")}/class-thumbnails/${
          classData.slug
        }-basic.jpg?blur=200&px=16&auto=format`}
      />
    </Div>
  );
};
